class FormBehavior {
  constructor(el) {
    this.el = el
    this.submitButton = this.el.querySelector('.webform-button--submit')

    // Add submitted class if it was already tried once
    this.submitButton.addEventListener('click', (event) => {
      
      this.checkValidity(event)
      this.el.classList.add('submitted')
    })

    // Hardcode error messages where we have to
    // @todo cleanup
    // const address = this.el.querySelector('#edit-address-fields-address')
    // const city    = this.el.querySelector('#edit-address-fields-city')
    // const state   = this.el.querySelector('#edit-address-fields-state-province')
    // const zip     = this.el.querySelector('#edit-address-fields-postal-code')

    // if (address !== null) {
    //     address.setAttribute('data-webform-required-error', 'This field is required.')
    // }
    // if (city !== null) {
    //     city.setAttribute(   'data-webform-required-error', 'This field is required.')
    // }
    // if (state !== null) {
    //     state.setAttribute(  'data-webform-required-error', 'This field is required.')
    // }
    // if (zip !== null) {
    //     zip.setAttribute(    'data-webform-required-error', 'This field is required.')
    // }

    // Add error classes for invalid field
    this.fields = this.el.querySelectorAll('.js-form-item')

    this.fields.forEach((field) => {
      const input = field.querySelector('input, select, textarea')

      if (input) {
        input.addEventListener('change', () => {
          console.log(input.validity)
          if (!input.validity.valid) {
            field.classList.add('error')
            field.setAttribute('data-webform-required-error', input.validity.patternMismatch ? input.getAttribute('data-webform-pattern-error') : input.getAttribute('data-webform-required-error'))
          } else {
            field.classList.remove('error')
          }

          this.checkSubmitBtn()
        })
      }
    })

    this.checkSubmitBtn()
  }

  checkValidity(event) {
    this.fields = this.el.querySelectorAll('.js-form-item')

    this.fields.forEach((field) => {
      const input = field.querySelector('input, select, textarea')
      if (input) {
        if (!input.validity.valid) {
          field.classList.add('error')
          field.setAttribute('data-webform-required-error', input.validity.patternMismatch ? input.getAttribute('data-webform-pattern-error') : input.getAttribute('data-webform-required-error'))
        } else {
          field.classList.remove('error')
        }
      }
    })

    if (this.el.querySelector('.js-form-item.error')) {
      event.preventDefault()
      window.scrollTo({top: document.querySelector('.area-title').getBoundingClientRect().top + document.documentElement.scrollTop - document.querySelector('header').clientHeight, behavior: 'smooth'})
    } /*else {
      dataLayer.push({'event': 'form_submit_success'})
    }*/
  }

  checkSubmitBtn() {
    this.fields = this.el.querySelectorAll('input[required], select[required], textarea[required]')

    let count = 0
    this.fields.forEach((field) => {
      if (field.value)
        count ++
    })

    if (count === this.fields.length)
      this.submitButton.classList.remove('grey')
    else 
      this.submitButton.classList.add('grey')
  }
}


export default FormBehavior
